import React, { useState } from "react"
import { Container } from "react-bootstrap"
import blogSearchNoResult from "../../assets/images/404/blog-404.svg"

function NoResult(props) {
  const [param, setParam] = useState("")

  // const handleInputChange = event => {
  //   setParam(event.target.value)
  // }

  // const handleInputSubmit = event => {
  //   if (event.key === "Enter") {
  //     window.location.href = `/blog/search?query=${param}`
  //   }
  // }

  // const handleButtonSubmit = () => {
  //   window.location.href = `/blog/search?query=${param}`
  // }

  return (
    <>
      <div className="main no-result-blog-search">
        <Container>
          <h3> No results found :( </h3>

          <img
            src={blogSearchNoResult}
            alt="Not found"
            // height="100"
            // width="100"
            className="nf"
          />

          <div className="p18">
            <p>
              It seems that the page you’re looking for is not available or
              missing. Please check the keyword you entered.
            </p>
          </div>

          {/* <div className="querybox">
            <div className="box-form position-relative">
              <input
                className="search-input"
                type="text"
                placeholder="search"
                name="search"
                id="search"
                value={param}
                onChange={handleInputChange}
                onKeyDown={handleInputSubmit}
              />
              <img
                src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/WDMcgU7GQa2w4lFAd2X8"
                alt="search"
                height="24"
                width="24"
                className="position-absolute icon"
                onClick={handleButtonSubmit}
              />
            </div>
          </div> */}
        </Container>
      </div>
    </>
  )
}

export default NoResult
